import { isGuniSms } from "constant/rolesAndAccess";
import { get, post, put } from "utils/api";

export async function getUserList(params) {
  const response = await get("/", params);
  return response.data;
}

export async function getOrganizationDetail(params) {
  const response = await get("/brand/details", params);
  return response.data;
}

export async function registerUser(userFormData = {}) {
  const response = await post(`/${isGuniSms ? "admin" : "subadmin"}/registeruser`, userFormData);

  return response.data;
}

export const getDedicatedNumbers = async data => {
  const response = await get(`/subadmin/dedicated-numberpool`, data);
  return response.data;
};

export const activateDedicatedNum = async id => {
  const response = await put(`/subadmin/activate-dedicated-numberpool/${id}`);
  return response.data;
};

export const deleteDedicatedNumber = async id => {
  const response = await put(`/subadmin/delete-dedicated-numberpool/${id}`);
  return response.data;
};
