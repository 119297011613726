import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { inject, observer } from "mobx-react";
import AppDropdown from "components/AppDropdown/AppDropdown";
import { messageTypes } from "constant/commonList";

const BundleType = ({ modalStore, endProps, addOptions = [] }) => {
  const [checked, setChecked] = useState("sms");

  let options = [...messageTypes, ...addOptions];

  options = options.map(item => {
    return {
      ...item,
      icon: item.value == "mms" ? "fas fa-envelope" : item.value === "dedicated number" ? "fas fa-hashtag" : "fas fa-sms"
    };
  });

  useEffect(() => {
    setChecked(modalStore.productType);
  }, [modalStore.productType]);
  console.log(modalStore.productType);

  return (
    <Col span={24} className="text-center">
      <AppDropdown
        options={options}
        value={checked}
        label={checked}
        btnClassName="text-uppercase px-3 fw-bold"
        btnProps={{
          light: true,
          withoutBg: true,
          postfixIcon: <i className="fa fa-chevron-down ms-5" />
        }}
        onClick={value => {
          setChecked(value);
          modalStore.setData("productType", value);
          modalStore.setIsMms(value === "mms");
        }}
      />
      {endProps && endProps}
    </Col>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(BundleType));
