import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { Col, Row, Tooltip, Table, Typography, Popover, Icon } from "antd";
import AlertModal from "components/Modals/AlertModal";
import AddContact from "./Modals/AddContact";
import QuickContact from "./Modals/QuickContact";
import { dateTimeFormat, debounceTime, sharedNumber } from "constant/constant";
import debounce from "lodash.debounce";
import SearchMenu from "components/SearchMenu/SearchMenu";
import ExportModal from "components/History/Modal/ExportNotificationModal";
import IconsMenu from "components/IconsMenu/IconsMenu";
import AssignModal from "components/Modals/AssignModal";
import AppButton from "components/AppButton/AppButton";
import ImportContactsModal from "components/Modals/ImportContactsModal";
import { toJS } from "mobx";
import { messageCounts } from "constant/messageConstants";
const { Column } = Table;
import GroupSearch from "components/FormItems/GroupSearch";

function View(props) {
  const {
    values,
    groupStore,
    modalStore,
    selectType = "checkbox",
    gatewayBulkStore,
    gatewayMmsStore,
    campaign = false,
    smallSize = false,
    showSelectedGroups = true,
    setFieldValue,
    handleChange = () => {},
    campaignGroup,
    authStore,
    messageStore,
    contactStore
  } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [noContactAlert, setNoContactAlert] = useState(false);
  const [warningAlert, setWaningAlert] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [record, setRecord] = useState();

  const [alertMessage, setAlertMessage] = useState("Something went wrong!");
  const [alertType, setAlertType] = useState("error");
  const [alertOpen, setAlertOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [hoverData, setHoverData] = useState(null);
  const [contactLimit, setContactLimit] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({ visible: false, action: null, loading: false });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchContact, setSearchContact] = useState(false);
  const [resetSearch, setResetSearch] = useState(false);

  const {
    search,
    setSearch,
    current,
    setPage,
    total,
    pageSize,
    setPageSize,
    selectedGroup,
    selectGroup,
    deleteGroup,
    groupList,
    getAllGroupList,
    setResponse,
    setGroupData,
    setGroupDataPage,
    setGroupDataPageSize,
    setGroupDataSearch,
    getExportGroupContacts,
    deleteOrEmptyGroups
  } = groupStore;

  const { user, allSenders } = authStore;

  const dataSource = groupList;

  const debouncedSave = useCallback(
    debounce(nextValue => setDebounceValue(nextValue), debounceTime),
    []
  );

  useEffect(() => {
    setPage(1);
    setSearch("");
    setPageSize(10);
    if (values?.groupObj && values?.group) groupStore.setSelectedGroups(values?.groupObj, values?.group);
  }, [campaign]);

  useEffect(() => {
    if (
      user?.role == "subaccount" &&
      !user?.access?.includes("mms") &&
      user?.role == "subaccount" &&
      !user?.access?.includes("sms") &&
      user?.role == "subaccount" &&
      !user?.access?.includes("group")
    ) {
      history.push("/");
    } else {
      sync();
    }
  }, [debounceValue, current, pageSize]);

  const sync = () => {
    setLoading(true);
    getAllGroupList().finally(() => {
      setLoading(false);
    });
  };
  0;

  const groupColumns = [
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      render: (text, record) => {
        return (
          <span role="button" className="fw-bold">
            {record?.assignedSubaccount && user?.role == "subaccount" && (
              <Tooltip title="Shared Group">
                <i className="fas fa-star me-2 text-info-color" />
              </Tooltip>
            )}
            {record?.assignedSubaccount && user?.role !== "subaccount" && (
              <Popover
                title="Assigned Subaccounts"
                content={record?.subAccount.map((item, index) => (
                  <Typography.Paragraph copyable key={index}>
                    {item.email}
                  </Typography.Paragraph>
                ))}
              >
                <i className="fas fa-users me-2 text-info-color" />
              </Popover>
            )}
            {text}
          </span>
        );
      }
    },
    {
      title: "Total Contacts",
      dataIndex: "totalContacts",
      className: "text-center",
      ellipsis: true,
      render: text => {
        return text;
      }
    }
  ];

  if (!campaign) {
    groupColumns.splice(4, 0, {
      dataIndex: "action",
      className: "text-center",
      ellipsis: false,
      render: (text, record) => {
        let menu = [
          { label: "View", value: "view", icon: "eye" },
          { label: "Run Campaign", value: "campaign", icon: "send" },
          // { label: "Delete", value: "delete", icon: "trash-alt" },
          { label: "Add Contacts", value: "add", icon: "user-plus" },
          parseInt(record.totalContacts) > 0 && {
            label: "Export",
            value: "export",
            icon: "download"
          }
        ].filter(Boolean);
        // if (user?.role === "subaccount" && !user?.roleAccess.find(item => item.feature === "group")?.delete && record?.assignedSubaccount && record?.subAccount.find(item => item === user?._id)) {
        //   menu.splice(
        //     menu.findIndex(item => item.value === "delete"),
        //     1
        //   );
        // }
        if (user?.role === "subaccount" && !user?.roleAccess.find(item => item.feature === "group")?.edit && record?.assignedSubaccount && record?.subAccount.find(item => item === user?._id)) {
          menu.splice(
            menu.findIndex(item => item.value === "add"),
            1
          );
        }
        if (menuVisible && hoverData._id == record._id) {
          return <IconsMenu options={menu} onClick={key => handleClick(key, record)} />;
        }
      }
    });
  }

  if (!smallSize) {
    groupColumns.splice(2, 0, {
      title: "Unsubscribed",
      dataIndex: "unsubscribe",
      className: "text-center",
      ellipsis: true,
      render: text => {
        return text;
      }
    });
  }

  if (!smallSize && !campaign) {
    groupColumns.splice(3, 0, {
      title: "Added Date",
      dataIndex: "createdAt",
      ellipsis: true,
      className: "",
      render: (text, record) => {
        return moment(record.createdAt).format(dateTimeFormat);
      }
    });
  }

  const createCampaignAction = values => {
    let data = {
      group: [values._id],
      groupObj: [values],
      unsubscribe: values?.unsubscribe,
      totalContacts: parseInt(values?.totalContacts)
    };
    gatewayBulkStore.setCampaignData(data);
    gatewayMmsStore.setCampaignData(data);
    history.push("/campaign/create");
    localStorage.setItem("campaign", JSON.stringify(data));
    localStorage.setItem("step", 0);
    messageStore.setCurrentMessage("");
    messageStore.setPhoneSubject("");
    messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
    messageStore.setCurrentImage(null);
    modalStore.setIsMms(false);
  };

  const viewGroupAction = record => {
    history.push(`/group/${record._id}`);
    setGroupDataPage(1);
    setGroupDataSearch("");
    setGroupDataPageSize(10);
  };

  const handleClick = (key, record) => {
    setRecord(record);
    setSelectedMenu(key);
    switch (key) {
      case "view":
        viewGroupAction(record);
        break;
      case "delete":
        setDeleteAlert(true);
        selectGroup(record);

        break;
      case "assign":
        selectGroup(record);
        setAssignModal(true);
        break;
      case "add":
        setResponse("group", record);
        modalStore.toggleModal("showAddContactsModal", true);

        break;
      case "campaign":
        if (record.totalContacts > 0) {
          createCampaignAction(record);
        } else {
          setNoContactAlert(true);
        }
        break;
      case "export":
        modalStore.toggleModal("showExportNotification", true);
        break;
    }
  };

  const handleExport = async (formValue, setTab) => {
    let data = null;
    data = await getExportGroupContacts(record._id, formValue);

    if (data.success) {
      modalStore.setData("exportMessage", data.message);
      modalStore.setData("exportEmail", data.email);
      // modalStore.toggleModal("showExportNotification", true);
      setTab("2");
      setAlertMessage(data.message);
      setAlertType("Success");
      // setAlertOpen(true);
    } else {
      setAlertMessage(data.message);
      setAlertType("Error");
      setAlertOpen(true);
    }
  };

  const handleSelectGroup = (id, record) => {
    const groupIds = _.uniq(id);
    const groupRecords = _.unionBy([...record, ...groupStore.selectedGroupObj], "_id");
    const { subscribe, total } = messageCounts(groupRecords);

    const contacts = values?.campaign_type === "notification" ? total : subscribe;
    if (groupIds.length > 1 && contacts > 3000) {
      setContactLimit(true);
    } else {
      groupStore.setSelectedGroups(record, groupIds);
      setFieldValue("group", groupIds);
      setFieldValue("groupObj", toJS(groupStore.selectedGroupObj));
      handleChange && handleChange(toJS(groupStore.selectedGroupObj), values);
    }
  };

  const rowSelection = {
    type: selectType,
    selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled Group",
      name: record.name
    })
  };

  const groupSelector = {
    type: modalStore.isMms ? "radio" : selectType,
    onChange: (id, record) => handleSelectGroup(id, record),
    selectedRowKeys: campaignGroup ? (Array.isArray(campaignGroup) ? campaignGroup : [campaignGroup]) : undefined,
    onSelectAll: (_, selectedRows) => {
      handleSelectGroup([...groupStore.selectedGroupObj?.map(({ _id }) => _id), ...selectedRows?.map(({ _id }) => _id)], selectedRows);
    }
  };

  const handleGroupAction = () => {
    setConfirmationModal(_ => ({ ..._, loading: true }));
    deleteOrEmptyGroups(selectedRowKeys, confirmationModal.action)
      .then(res => {
        if (res.status) {
          setSelectedRowKeys([]);
          setDeleteSuccess(true);
        }
      })
      .finally(() => {
        setConfirmationModal(_ => ({ ..._, loading: false, visible: false }));
      });
  };

  const subAccPermission = !(
    user?.role === "subaccount" &&
    !user?.roleAccess.find(item => item.feature === "group")?.delete &&
    record?.assignedSubaccount &&
    record?.subAccount.find(item => item === user?._id)
  );

  const renderActionButtons = () => {
    if (selectedRowKeys.length > 0) {
      return (
        <>
          <AppButton
            label="Delete"
            onClick={() => setConfirmationModal(_ => ({ ..._, action: "delete", visible: true }))}
            light
            size="default"
            className="ms-2"
            prefixIcon={<i className="fas fa-trash-alt me-2" />}
          />
          <AppButton
            label="Empty"
            onClick={() => setConfirmationModal(_ => ({ ..._, action: "empty", visible: true }))}
            light
            size="default"
            className="ms-2"
            prefixIcon={<i className="far fa-file me-2" />}
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Row className={campaign ? `bg-white` : "my-3 w-100 rounded-xl bg-white shadow mx-auto p-3"} gutter={[10, 20]} style={{ height: modalStore.quickMessage ? "auto" : "fit-content" }}>
        {!campaign && (
          <Col span={24}>
            <Typography.Title level={4} className="text-info-color">
              Contact Groups
            </Typography.Title>
          </Col>
        )}
        <Col span={24}>
          <SearchMenu
            primaryBtn={selectedRowKeys.length === 0}
            stepView={campaign}
            placeholder={`Search for Group Name`}
            value={search}
            onSearch={e => {
              setSearch(e.target.value);
              debouncedSave(e.target.value);
            }}
            size="default"
            label={smallSize ? "Please use Web portal to create new group from Excel or CSV files." : "Add Group"}
            onClick={() => {
              if (smallSize) return;
              modalStore.toggleModal("importContactsModal", true);
              setResponse("group", undefined);
              setGroupData(null);
            }}
            btn={!smallSize}
            icon={campaign}
            secondaryBtn={selectedRowKeys.length === 0}
            secondaryBtnProps={{
              onClick: () => history.push("/opt-out"),
              label: "Opt-Out List",
              size: "default"
            }}
            findContactBtn={selectedRowKeys.length == 0}
            findContactProps={{
              onClick: () => setSearchContact(true),
              label: "Find Contact"
            }}
            endProps={<>{renderActionButtons()}</>}
          />
        </Col>
        {campaign && showSelectedGroups && selectType === "checkbox" && !modalStore.isMms ? (
          <Col span={24} className="text-start p-0 ps-2">
            {campaignGroup && campaignGroup?.length >= 2 ? (
              <div className="fw-bold">
                Selected Groups - <span className="fw-normal font-size-12 badge-soft-info">{campaignGroup?.length}</span>
              </div>
            ) : (
              <span className="text-muted fs-6">* For more than one group, maximum combined contact limit is 3,000.</span>
            )}
          </Col>
        ) : null}
        {smallSize && groupList.length == 0 && !loading && (
          <Col md={14} span={24}>
            Use web portal to create Group
          </Col>
        )}
        <Col span={24}>
          <Table
            className={campaign ? "border rounded" : ""}
            loading={loading}
            pagination={{
              defaultCurrent: 1,
              current: current,
              total: total,
              pageSize: pageSize,
              onChange: current => setPage(current),
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
              className: "float-start",
              simple: smallSize,
              hideOnSinglePage: true,
              showQuickJumper: true
            }}
            onRow={record => {
              return {
                onMouseEnter: () => {
                  setMenuVisible(true);
                  setHoverData(record);
                },
                onMouseLeave: () => {
                  setMenuVisible(false);
                  setHoverData(null);
                }
              };
            }}
            rowSelection={campaign ? groupSelector : rowSelection}
            scroll={{
              x: 750,
              y: 655
            }}
            rowKey={record => record._id}
            dataSource={dataSource}
          >
            {groupColumns &&
              groupColumns.map((column, index) => {
                return (
                  <Column
                    title={column.title}
                    dataIndex={column.dataIndex}
                    key={index}
                    className={column.className}
                    ellipsis={column.ellipsis}
                    render={column.render}
                    onCell={record => {
                      if (column.dataIndex !== "action") {
                        return {
                          onClick: event => {
                            if (campaign) {
                              if (selectType === "radio") handleSelectGroup([record._id], [record]);
                            } else {
                              viewGroupAction(record);
                            }
                          }
                        };
                      }
                    }}
                  />
                );
              })}
          </Table>
        </Col>
      </Row>

      <ImportContactsModal sync={sync} />

      {modalStore.showExportNotification && <ExportModal handleExport={handleExport} className="mb-3" />}
      {!campaign && (
        <>
          <AddContact />
          <QuickContact />
          <AlertModal
            visible={alertOpen}
            title={alertType}
            description={alertMessage}
            primaryBtnProps={{
              label: "Ok",
              onClick: () => setAlertOpen(false)
            }}
            closable
            onCancel={() => setAlertOpen(false)}
            error={alertType === "Error"}
          />
          <AlertModal
            visible={deleteAlert}
            title={`Deleting ${selectedGroup && selectedGroup.name}`}
            description="Are you sure you want to delete this Group? This action cannot be undone."
            secondaryBtn
            primaryBtnProps={{
              onClick: () => {
                setDeleteLoading(true);
                deleteGroup(selectedGroup._id)
                  .then(res => {
                    if (res.status) {
                      setDeleteSuccess(true);
                    }
                  })
                  .finally(() => {
                    setDeleteLoading(false);
                    setDeleteAlert(false);
                  });
              }
            }}
            secondaryBtnProps={{
              onClick: () => {
                setDeleteAlert(false);
                selectGroup(undefined);
              }
            }}
            loading={deleteLoading}
            error
          />
          <AlertModal
            visible={deleteSuccess}
            title={confirmationModal.action === "empty" ? "Selected groups are empty now!" : "Selected groups are deleted!"}
            primaryBtnProps={{
              label: "Ok",
              onClick: () => setDeleteSuccess(false)
            }}
            closable
            onCancel={() => setDeleteSuccess(false)}
          />
          <AlertModal
            visible={noContactAlert}
            title="Zero Contacts"
            content="No contacts in this group, cannot run campaign."
            secondaryBtn
            primaryBtnProps={{
              onClick: () => setNoContactAlert(false),
              label: "Ok"
            }}
            secondaryBtnProps={{
              onClick: () => {
                modalStore.toggleModal("showAddContactsModal", true);
                setNoContactAlert(false);
              },
              label: "Add Contacts"
            }}
            error
          />
          {/* <AlertModal
            error
            visible={warningAlert}
            title={`Can't perform`}
            description={`This group is in use. You can't ${selectedMenu ? `${selectedMenu} contact in` : "make changes on"} this group now.`}
            primaryBtnProps={{
              label: "Ok",
              onClick: () => setWaningAlert(false)
            }}
            closable
            onCancel={() => setWaningAlert(false)}
          /> */}
        </>
      )}
      <AssignModal visible={assignModal} onCancel={() => setAssignModal(false)} />
      <AlertModal
        visible={contactLimit}
        title="Limit Exceeds"
        description={"Maximum contacts limit exceed of 3,000 contacts!"}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setContactLimit(false)
        }}
        closable
        onCancel={() => setContactLimit(false)}
        error
      />
      <AlertModal
        visible={confirmationModal.visible}
        title={`${confirmationModal.action === "delete" ? "Delete" : "Empty"} Groups`}
        description={`Are you sure you want to ${confirmationModal.action} group(s)? This action cannot be undone.`}
        secondaryBtn
        primaryBtnProps={{
          onClick: () => handleGroupAction(confirmationModal.action),
          label: confirmationModal.action === "delete" ? "Delete" : "Empty"
        }}
        secondaryBtnProps={{
          onClick: () => setConfirmationModal({ visible: false, action: null, loading: false }),
          label: "Cancel"
        }}
        loading={confirmationModal.loading}
        error
      />
      <AlertModal
        withoutHeader
        visible={searchContact}
        title={
          <div className="m-3">
            <h4 className="text-center pt-3 mb-0">Search Contact</h4>
            <p className="text-muted font-size-12 font-size-14 fw-normal">Search contact by contact name or contact number</p>
          </div>
        }
        description=""
        btn={false}
        modalWidth={500}
        closable
        onCancel={() => {
          setSearchContact(false);
          setResetSearch(true);
        }}
        endPropContainerClass="w-100 mt-0 flex-column align-items-center p-3"
        endComponent={
          <div className="w-100 px-2">
            <GroupSearch resetSearch={resetSearch} onResetComplete={() => setResetSearch(false)} />
          </div>
        }
      />
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore,
  groupStore: stores.store.groupStore,
  modalStore: stores.store.modalStore,
  categoryStore: stores.store.categoryStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  messageStore: stores.store.messageStore,
  authStore: stores.store.authStore,
  contactStore: stores.store.contactStore
}))(observer(View));
