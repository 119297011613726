import * as XLSX from "xlsx";
import { validAusNumberRegExpDeluxe } from "constant/messageConstants";
import { Tag } from "antd";
import React from "react";
import { cookie } from "utils/cookie";
import { AUTH_USER_BALANCE, AUTH_USER_INFO, batchMaxContact } from "./constant";

const readBatchXls = ({ file, setFieldValue, setContactLimitAlert, setFileSupportAlert, setHeaderAlert, setAlert, setAlertMsg }) => {
  var fileReader = new FileReader();
  let rowObject = [];
  let headers = [];
  fileReader.onload = function(event) {
    var data = event.target.result;
    var workbook = XLSX.read(data, { type: "binary" });
    headers = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
    rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);

    if (rowObject.length < 1) {
      setAlert(true);
      setAlertMsg("Your file has no Contacts! Please add contacts.");
      // setFileSupportAlert(true);
      setFieldValue("file", null);
      setFieldValue("file", null);
      return false;
    }

    if (rowObject.length > batchMaxContact) {
      setAlert(true);
      setAlertMsg(`Contact upload limit is ${batchMaxContact}`);
      // setContactLimitAlert(true);
      setFieldValue("file", null);
      setFieldValue("file", null);
      return false;
    }

    if (!headers.includes("contacts")) {
      setAlert(true);
      setAlertMsg('"contact" header is required!');
      setFieldValue("fileName", null);
      setFieldValue("file", null);
      // setHeaderAlert(true);
      return false;
    }

    setFieldValue("file", file);
  };
  fileReader.readAsBinaryString(file);
};

const readXls = ({ file, setFieldValue, authStore, setContactLimitAlert, setAlert, setAlertMsg }) => {
  var fileReader = new FileReader();
  let rowObject = [];
  let headers = [];
  fileReader.onload = function(event) {
    var data = event.target.result;
    var workbook = XLSX.read(data, { type: "binary" });
    headers = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
    rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
    if (rowObject.length > authStore.user?.contactLimit) {
      setContactLimitAlert(true);
      setFieldValue("file", null);
      return false;
    } else {
      try {
        setFieldValue("headers", headers);
        setFieldValue("xlsFileData", rowObject);
        setFieldValue("file", file);
        setFieldValue("name", file.name);
      } catch (er) {
        setAlertMsg(er);
        setAlert(true);
        console.log(er);
        return false;
      }
    }
  };
  fileReader.readAsBinaryString(file);
};

export function beforeUpload({
  authStore,
  file,
  setFieldValue,
  setHeaderAlert,
  setFileSizeAlert,
  setFileSupportAlert,
  setContactLimitAlert,
  optout = false,
  isBatchFile = false,
  setAlert,
  setAlertMsg
}) {
  if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      setFileSizeAlert(true);
    } else {
      const method = isBatchFile ? readBatchXls : readXls;
      method({
        file,
        optout,
        authStore,
        setFieldValue,
        setHeaderAlert,
        setFileSupportAlert,
        setContactLimitAlert,
        setAlert,
        setAlertMsg
      });
    }
  } else {
    setFileSupportAlert(true);
  }
  return false;
}

export async function checkNumberwithRegex(arr) {
  let validNumber = [];
  await arr.map(item => {
    if (String(item).match(validAusNumberRegExpDeluxe)) {
      validNumber.push(item);
    }
  });
  if (validNumber.length > 0) {
    return { status: true, validNumber };
  } else {
    return { status: false, validNumber };
  }
}

export const excelFileHeader = ["number", "country", "amount", "product_id", "active", "type"];

function checkData(rowObject, headers) {
  if (!excelFileHeader.map(val => headers.includes(val)).every(item => item)) return false;
  const dataArr = excelFileHeader.map(val => rowObject[val]);
  if (dataArr.includes(undefined)) return false;
  return true;
}

export function checkFileData({ file, setAlert, setFieldValue }) {
  var fileReader = new FileReader();
  let rowObject = [];
  let headers = [];
  fileReader.onload = function(event) {
    var data = event.target.result;
    var workbook = XLSX.read(data, { type: "binary" });

    headers = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
    rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);

    // check all header are available in file and atleast one data row
    if (!checkData(rowObject[0], headers)) {
      setAlert(true);
      setFieldValue("file", "");
      return false;
    }
    setFieldValue("file", file);
  };
  fileReader.readAsBinaryString(file);
}

export const getSmsMMsTag = text => {
  if (text === "common") {
    return (
      <>
        <Tag color={"orange"} className="mx-2">
          SMS
        </Tag>
        <Tag color={"cyan"} className="mx-2">
          MMS
        </Tag>
      </>
    );
  } else {
    return <>{text ? <Tag color={text === "sms" ? "orange" : "cyan"}>{text?.toUpperCase()}</Tag> : null}</>;
  }
};

export const dedicatedNumTypes = [
  { label: "SMS & MMS", value: "common" },
  { label: "SMS", value: "sms" },
  { label: "MMS", value: "mms" }
];

export const afterTaxPrice = price => {
  if (!price) return "N/A";
  const taxedPrice = ((10 / 100) * price).toFixed(2);
  return (parseFloat(price) + parseFloat(taxedPrice)).toFixed(2);
};

export const addPercentage = (number, roundOff = false, percentage = 10) => {
  let data = ((percentage / 100) * parseFloat(number) + parseFloat(number)).toFixed(3);
  if (roundOff) {
    data = Math.round(data);
  }
  return data;
};

/**
 *
 * @description remove the data list that stores in localstorage to get latest data.
 */
export const removeDataCookies = () => {
  cookie.erase(AUTH_USER_INFO);
  // cookie.erase(AUTH_GROUP_LIST);
  cookie.erase(AUTH_USER_BALANCE);
  // cookie.erase(AUTH_TEMPLATE_LIST);
};

export const objectToFormData = (obj = {}, instance) => {
  Object.entries(obj).forEach(([key, value]) => {
    instance?.append(key, Array.isArray(value) ? JSON.stringify(value) : value);
  });
  return instance;
};
