import { runInAction, decorate, observable } from "mobx";
import { activateDedicatedNum, deleteDedicatedNumber, getDedicatedNumbers, registerUser } from "service/organizationService";

class OrganizationStore {
  response = undefined;
  isSubmitting = false;
  total = 0;
  pageSize = 10;
  current = 1;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";
  list = [];

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  registerUser = async formData => {
    try {
      runInAction(() => {
        this.isSubmitting = true;
      });

      const response = await registerUser(formData);

      runInAction(() => {
        this.response = response;
        this.isSubmitting = false;
      });

      return this.response;
    } catch (error) {
      runInAction(() => {
        this.response = error;
        this.isSubmitting = false;
      });
      return this.response;
    }
  };

  // dedicated numbers

  getDedicatedNumbers = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize
    };
    if (this.search) params.search = this.search;
    const response = await getDedicatedNumbers(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = response;
        this.list = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  activateDedicatedNum = async id => {
    const response = await activateDedicatedNum(id);
    try {
      runInAction(() => {
        this.response = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  deleteDedicatedNumber = async id => {
    const response = await deleteDedicatedNumber(id);
    try {
      runInAction(() => {
        this.response = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(OrganizationStore, {
  response: observable,
  isSubmitting: observable,
  total: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  sortField: observable,
  sortOrder: observable,
  list: observable
});

export default new OrganizationStore();
