import { isGuniSms } from "constant/rolesAndAccess";
import { get, post, put, deleteRequest } from "utils/api";

// get all dedicated numbers list (admin)
export const getAllDedicatedNo = async params => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/dedicated-number${isGuniSms ? "" : "pool"}/`, params);
  return response.data.data;
};

// get dedicated numbers history (admin)
export const dedicatedNumHistory = async (id, data) => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/get-number-history/${id}`, data);
  return response.data.data;
};

// get dedicated numbers price by product id (admin)
export const getNumberPrice = async id => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/get-price/${id}`);
  return response.data.data;
};

// release dedicated Number of user (admin)
export const releaseDedicationNumber = async id => {
  const response = await put(`/${isGuniSms ? "admin" : "subadmin"}/cancel-dedicated-number/${id}`);
  return response.data.data;
};

// delete dedicated number admin
export const deleteDedicatedNumber = async id => {
  const response = await deleteRequest(`/${isGuniSms ? "admin" : "subadmin"}/delete-dedicated-number${isGuniSms ? "" : "pool"}/${id}`);
  return response.data;
};

// add single dedicated number admin
export const addDedicatedNumber = async data => {
  const response = await post(`/${isGuniSms ? "admin" : "subadmin"}/dedicated-number${isGuniSms ? "" : "pool"}`, data);
  return response.data;
};

// edit dedicated number admin
export const editDedicatedNumber = async (id, data) => {
  const response = await put(`/${isGuniSms ? "admin" : "subadmin"}/edit-dedicated-number${isGuniSms ? "" : "pool"}/${id}`, data);
  return response.data;
};

// acitve/inActive dedicated number admin
export const activateDedicatedNum = async params => {
  const response = await put(`/${isGuniSms ? "admin" : "subadmin"}/activate-dedicated-number${isGuniSms ? "" : "pool"}/${params}`);
  return response.data;
};

// acitve/inActive dedicated number admin
export const addBulkDedicatedNums = async data => {
  const response = await post(`/${isGuniSms ? "admin" : "subadmin"}/bulk-upload-dedicated-number${isGuniSms ? "" : "pool"}`, data);
  return response.data;
};

// get all dedicated numbers list user
export const getDedicatedNumList = async params => {
  const response = await get(`/numberassign${isGuniSms ? "" : "/getdedicatedPool"}`, params);
  return response.data.data;
};

// get purchased dedicated number
export const getPurchasedDedicatedNumList = async params => {
  const response = await get(`/numberassign/getUserNumber`, params);
  return response.data.data;
};

// release or remove subscription of dedicated number
export const releaseDedicatedNumber = async id => {
  const response = await put(`/numberassign/${id}`);
  return response.data;
};

// purchase dedicated no
export const purchaseDedicatedNum = async data => {
  const response = await post(`/numberassign`, data);
  return response.data;
};
