export const roles = ["user", "admin", "superAdmin", "guest", "subaccount", "trial"];

export const roleAccessList = [
  "sms",
  "mms",
  "group",
  "integration"
  // "template",
  // "fileScheduler",
  // "emailSms",
  // "history"
];

export const isGuniSms = window.location.hostname.includes("gunisms.com.au") || window.location.host.includes("localhost:5111");
// export const isGuniSms = window.location.hostname.includes("gunisms.com.au");
